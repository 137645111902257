"use client";

import { MINDSET_PHONE_NUMBER } from "@/lib/constants";
import { formatPhoneNumber } from "@/utils/helpers";
import { PhoneIcon } from "@chakra-ui/icons";
import { Link } from "@chakra-ui/next-js";
import { Box, Button, Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const StickyPhoneBanner = () => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const bannerOffset = 87; // Hardcoded header height
    if (window.scrollY > bannerOffset) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <>
      <Button
        as={Link}
        href={`tel:${MINDSET_PHONE_NUMBER}`}
        borderRadius="0px"
        w="100%"
        position={isSticky ? "fixed" : "relative"}
        top={isSticky ? "0" : "auto"}
        zIndex="100"
        bg="brand.secondary"
        color="white"
        borderBottomWidth="1px"
        borderBottomColor="#B9CAD0"
        fontSize="20px"
        fontWeight="700"
        h="48px"
        _active={{
          bg: "brand.secondary",
          textDecoration: "none",
        }}
        display={{
          base: "flex",
          md: "none",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Icon as={PhoneIcon} mr="8px" />
        <Text as="span">{formatPhoneNumber(MINDSET_PHONE_NUMBER)}</Text>
      </Button>
      {isSticky && <Box h="48px"></Box>}
    </>
  );
};

export default StickyPhoneBanner;
