"use client";

import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import "@fontsource/manrope";

export const landing_theme = extendTheme({
  fonts: {
    heading: `'Manrope', sans-serif`,
    body: `'Manrope', sans-serif`,
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: "white",
      },
    }),
  },
  colors: {
    brand: {
      primary: "#77d5b3",
      secondary: "#3e3e56",
      brighter: "#7091A3",
    },
  },
  components: {
    Button: {
      // baseStyle: {
      //   _hover: {
      //     bg: "red",
      //   },
      // },
      variants: {
        primary: {
          bg: "brand.secondary",
          color: "white",
          fontWeight: 500,
          _hover: {
            bg: "#2D2D3E",
            textDecoration: "none",
            "&:disabled": {
              bg: "brand.secondary",
            },
          },
        },
        secondary: {
          bg: "brand.primary",
          color: "brand.secondary",
        },
      },
    },
    Link: {
      variants: {
        underlined: {
          fontWeight: "600",
          textDecoration: "underline",
          color: "brand.secondary",
          _hover: {
            color: "brand.brighter",
          },
        },
      },
    },
    Input: {
      baseStyle: {
        mb: "20px",
      },
      variants: {
        auth: {
          field: {
            py: "22px",
            pl: "26px",
            pr: "48px",
            borderRadius: "8px",
            h: "68px",
            border: "1px solid #CDE2E7",
            fontSize: "16px",
            fontWeight: "500",
          },
        },
      },
    },
  },
  layerStyles: {
    blog_post_body: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#3D565F",
      p: {
        mb: "24px",
      },
      ul: {
        pl: "24px",
        mb: "16px",
      },
      li: {
        p: {
          mb: "8px",
        },
      },
      h2: {
        fontSize: "26px",
        fontWeight: "700",
        lineHeight: "34px",
        letterSpacing: "-0.52px",
        color: "#3E3E56",
        mb: "24px",
      },
      h3: {
        fontSize: "18px",
        fontWeight: "700",
        lineHeight: "23px",
        color: "#071920",
        mb: "24px",
      },
    },
    responsive: {
      flexDirection: {
        base: "column",
        md: "row",
      },
    },
  },
});

export function LandingThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ChakraProvider theme={landing_theme}>{children}</ChakraProvider>;
}
