"use client";

import { Link } from "@chakra-ui/next-js";
import { ReactNode } from "react";

const ClientLink = ({
  href,
  children,
  target,
  ariaLabel,
}: {
  href: string;
  children: ReactNode;
  target?: string;
  ariaLabel?: string;
}) => {
  return (
    <Link
      href={href}
      aria-label={ariaLabel}
      target={target}
      _hover={{
        cursor: "pointer",
        textDecoration: "none",
      }}
    >
      {children}
    </Link>
  );
};

export default ClientLink;
