import { FieldErrors } from "react-hook-form";

export const getLeadSource = () => {
  const defaultSource = "HomePage";
  if (typeof window === "undefined") return defaultSource;
  const leadSource = window.location.pathname;
  return leadSource || defaultSource;
};

export const getUTMParam = (param: string) => {
  if (typeof window === "undefined") return "";
  const params = new URLSearchParams(window.location.search);
  return params.get(param) || "";
};

export const checkFormError = (errors: FieldErrors, id: string) => {
  if (errors[id] && errors[id].message) {
    return errors[id].message as string;
  }
  return "";
};

export const formatPhoneNumber = (phone: string) => {
  return phone ? phone.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3") : "";
};

export const downloadBase64File = (base64: string, fileName: string) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Convert to a Blob
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray]);

  // Create a download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();

  // Clean up the URL Object
  URL.revokeObjectURL(link.href);
};

export const checkBooleanExists = (value: any) => {
  return value === true || value === false;
};

export const merge_tasks = (user: any, tasks: any[]) => {
  if (!user) {
    return tasks;
  }
  return tasks.map((task) => {
    if (task.title === "Provide Basic Info") {
      if (
        user.firstName &&
        user.lastName &&
        user.ssn &&
        user.dateOfBirth &&
        user.email &&
        user.streetAddress &&
        user.city &&
        user.state &&
        user.zip
      ) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Date of Birth") {
      if (user.dateOfBirth) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Social Security Number (SSN)") {
      if (user.ssn) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Your Address") {
      if (user.streetAddress) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Sign Agreements") {
      if (user.powerform_url.envelopStatus === "envelope-completed") {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Application: General Info") {
      if (
        user.firstName &&
        user.lastName &&
        user.ssn &&
        user.dateOfBirth &&
        user.gender &&
        checkBooleanExists(user.isUnableWork) &&
        checkBooleanExists(user.isDeniedSSN)
      ) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Application: Medical Info") {
      if (
        user.medicalCondition &&
        user.mailingAddress &&
        checkBooleanExists(user.isReside) &&
        checkBooleanExists(user.isLowVision) &&
        checkBooleanExists(user.isDisabled) &&
        user.startDateOfDisability
      ) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Application: Contact Info") {
      if (
        user.phoneNumber &&
        user.placeOfBirth &&
        user.timeToCall &&
        checkBooleanExists(user.isDeadlyCondition) &&
        checkBooleanExists(user.isUSCitizen)
      ) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Application: Language Info") {
      if (
        user.languageForSpeaking &&
        user.languageForReading &&
        user.typeOfCitizenship &&
        checkBooleanExists(user.isSpeakEnglish) &&
        checkBooleanExists(user.isReadEnglish) &&
        checkBooleanExists(user.isWriteEnglish) &&
        checkBooleanExists(user.isMarried)
      ) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Application: Family Info") {
      if (
        user.motherName !== undefined &&
        user.fatherName !== undefined &&
        checkBooleanExists(user.isPriorMarriage) &&
        checkBooleanExists(user.isMillitary) &&
        checkBooleanExists(user.isAppliedPDB)
      ) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Application: Benefits Info") {
      if (
        checkBooleanExists(user.isOtherName) &&
        checkBooleanExists(user.isWorkInCurYear) &&
        checkBooleanExists(user.isSelfEmployedPrevYear) &&
        checkBooleanExists(user.isSelfEmployedCurYear) &&
        checkBooleanExists(user.isPriorApplications)
      ) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Application: Employment Info") {
      if (
        checkBooleanExists(user.isWorkedOutsideUS) &&
        checkBooleanExists(user.isAgreeWithEarningHistory) &&
        checkBooleanExists(user.isWorkedTaxesNotDecucted) &&
        checkBooleanExists(user.isOwnBankAccount)
      ) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Application: Identity Info") {
      if (
        checkBooleanExists(user.isOtherSSNumber) &&
        user.heightWithoutShoes &&
        user.weightWIthoutShoes &&
        checkBooleanExists(user.isReceiveMoneyUnableToWork) &&
        checkBooleanExists(user.isExpectMoneyFromRecentEmployer) &&
        checkBooleanExists(user.isOneParent)
      ) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Education History") {
      if (user.template_count.highSchoolInfoCount > 0) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add College History") {
      if (user.template_count.collegeHistoryCount > 0) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Work History") {
      if (user.template_count.workHistoryCount > 0) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Doctor Visits") {
      if (user.template_count.doctorVisitsCount > 0) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Medication Details") {
      if (user.template_count.medicationDetailsCount > 0) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Children Information") {
      if (user.template_count.childrenInfoCount > 0) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Marriage(s)") {
      if (user.template_count.marriageInfoCount > 0) {
        return {
          ...task,
          completed: true,
        };
      }
    } else if (task.title === "Add Emergency Contact") {
      if (user.template_count.emergencyContactCount > 0) {
        return {
          ...task,
          completed: true,
        };
      }
    }

    return task;
  });
};
