import { ChildrenInformationPayload } from "@/actions/templates/ChildrenInformation/PostChildrenInformation";

export const axiosBaseUrlOld = process.env.NEXT_PUBLIC_BACKEND_API_URL_OLD;
export const axiosBaseUrl = process.env.NEXT_PUBLIC_BACKEND_API_URL;
export const POSTHOG_HOST = "https://us.i.posthog.com";
export const GOOGLE_ADDRESS_API_KEY = "AIzaSyD-U5ZnygQUb0GXIxHbB1D0EYG3pg3foz0";
export const CONTENTFUL_SPACE_ID = "d5cnutywizq7";
export const CONTENTFUL_ACCESS_TOKEN =
  "NGBWWvVvgTNSuZ9NjWerWIhi47T-fEW9K_b72B6RrbA";
export const MINDSET_PHONE_NUMBER = "8888204270";

export const META_DESCRIPTION =
  "Helping you apply for Social Security disability benefits.";

export const REQUIRED_FIELD = "This field is required.";
export const SERVER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const HISTORY_TEMPLATES: {
  [key: string]: {
    title: string;
    columns: string[];
    defaultValues: any;
  };
} = {
  Education: {
    title: "Education History",
    columns: [
      "High School",
      "Date of Certificate",
      "Highest Grade",
      "Location",
    ],
    defaultValues: {
      Id: undefined,
      IsGED: undefined,
      DateOfCertificate: "",
      IsStateType: undefined,
      IsHighSchoolDiploma: undefined,
      HighGradeCompleted: "",
      LastHighSchoolAttended: "",
      HighSchoolCity: "",
      HighSchoolState: "",
      IsSpecialEducation: undefined,
      StartGrade: "",
      EndGrade: "",
    },
  },
  College: {
    title: "College History",
    columns: ["Name", "Start Date", "End Date", "Degree(s)"],
    defaultValues: {
      Id: undefined,
      CollegeName: "",
      Address: "",
      CollegeStartDate: "",
      CollegeEndDate: "",
      DegreesCertificateLicenses: "",
    },
  },
  Work: {
    title: "Work History",
    columns: ["Company Name", "Job Title", "Employed From", "Employed To"],
    defaultValues: {
      Id: undefined,
      CompanyName: "",
      AddressTwo: "",
      Zip: "",
      JobTitle: "",
      IsCurrentlyWorking: undefined,
      IsSelfEmployed: undefined,
      EmployedFrom: "",
      EmployedTo: "",
      HoursPerDay: "",
      DaysPerWeek: "",
      PayAmount: "",
      PayFrequency: "",
    },
  },
  Doctors: {
    title: "Doctor Visits",
    columns: [
      "Facility Name",
      "Date of Visit",
      "Facility Address",
      "Reason for Visit",
    ],
    defaultValues: {
      Id: undefined,
      DoctorName: "",
      DateOfVisit: "",
      ReasonForVisit: "",
      Diagnosis: "",
    },
  },
  Medication: {
    title: "Medication History",
    columns: ["Physician Name", "Prescription Name", "Start Date", "Details"],
    defaultValues: {
      Id: undefined,
      PhysicianId: undefined,
      MedicationId: undefined,
      PrescriptionDetails: "",
      PrescriptionStartDate: "",
    },
  },
  Marriage: {
    title: "Marriage History",
    columns: [
      "Marriage Date",
      "Location",
      "Divorce Date",
      "Spouse Date of Birth",
      "Spouse Name",
    ],
    defaultValues: {
      Id: undefined,
      CityOfMarriage: "",
      StateOfMarriage: "",
      CityOfDivorce: "",
      DateOfMarriage: "",
      DateOfDivorce: "",
      StateOfDivorce: "",
      SpouseName: "",
      SpouseDob: "",
      SpouseSSN: "",
    },
  },
  Children: {
    title: "Children Information",
    columns: ["First Name", "Middle Name", "Last Name"],
    defaultValues: {
      Id: undefined,
      FirstName: "",
      MiddleName: "",
      LastName: "",
      IsCurrentlyMarried: undefined,
      IsDisabled: undefined,
    } as Partial<ChildrenInformationPayload>,
  },
  Contacts: {
    title: "Emergency Contacts",
    columns: ["First Name", "Last Name", "Email", "Phone Number"],
    defaultValues: {
      Id: undefined,
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      StreetAddress: "",
      City: "",
      State: "",
      Zip: "",
      Relationship: "",
    },
  },
};

export const LANGUAGE_OPTIONS = [
  "English",
  "Spanish",
  "French",
  "Arabic",
  "Russian",
  "German",
  "Chinese",
  "Japanese",
  "Hindi",
  "Italian",
  "Others",
];

export const constants = {
  accessTokenOldKey: "accessTokenOld",
  accessTokenKey: "accessToken",
};

export const EVENT_LIBRARY = {
  SAVED_APPLICATION: "Saved Application",
  FORGOT_PASSWORD: "Forgot Password",
  LOGGED_IN: "Logged In",
  LOGGED_OUT: "Logged Out",
  RESET_PASSWORD: "Reset Password",
  SUBMITTED_LEAD: "Submitted Lead",
  SUBMITTED_PREQUAL: "Submitted Prequal",
  SUBMITTED_INTAKE: "Submitted Intake",
  REGISTERED: "Registered",
  ADDED_HISTORY: "Added History",
  UPDATED_HISTORY: "Updated History",
  DELETED_HISTORY: "Deleted History",
  SENT_MESSAGE: "Sent Message",
  UPLOADED_DOCUMENT: "Uploaded Document",
  DELETED_DOCUMENT: "Deleted Document",
  DOWNLOADED_DOCUMENT: "Downloaded Document",
  READ_NOTIFICATION: "Read Notification",
  UPDATED_PASSWORD: "Updated Password",
  UPDATED_NOTIFICATIONS: "Updated Notifications",
  ENCOUNTERED_ERROR: "Encountered Error",
};

export const ROUTE_PATHS = {
  ROOT: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  PREQUALIFY: "/prequalify",
  FORGOT_PASSWORD: "/forgot",
  TABLET: {
    SPLASH: "/tablet/splash",
  },
  INTAKE: {
    ROOT: "/intake",
    AGREE: "/intake/agree",
    DOCUSIGNINFO: "/intake/docusignInfo",
  },
  DASHBOARD: {
    HOME: "/dashboard/home",
    TASKS: "/dashboard/tasks",
    HELP: "/dashboard/help",
    DOCUMENTS: "/dashboard/documents",
    NOTIFICATIONS: "/dashboard/notifications",
    CONTACT: "/dashboard/contact",
    SETTINGS: "/dashboard/settings",
    APPLICATION: {
      ROOT: "/dashboard/application",
      BASIC: "/dashboard/application/basic",
      AGREEMENTS: "/dashboard/application/agreements",
      GENERAL: "/dashboard/application/general",
      MEDICAL: "/dashboard/application/medical",
      CONTACT: "/dashboard/application/contact",
      LANGUAGE: "/dashboard/application/language",
      FAMILY: "/dashboard/application/family",
      BENEFITS: "/dashboard/application/benefits",
      EMPLOYMENT: "/dashboard/application/employment",
      IDENTITY: "/dashboard/application/identity",
    },
    HISTORY: {
      ROOT: "/dashboard/history",
      EDUCATION: "/dashboard/history/education",
      COLLEGE: "/dashboard/history/college",
      WORK: "/dashboard/history/work",
      DOCTORS: "/dashboard/history/doctors",
      MEDICATION: "/dashboard/history/medication",
      MARRIAGE: "/dashboard/history/marriage",
      CHILDREN: "/dashboard/history/children",
      EMERGENCY: "/dashboard/history/emergency",
    },
  },
};

export const STR_ARR_DELIMITER = ";;;";

export const INTAKE_SYMPTOMS = {
  Anxiety: [
    "Excessive worry",
    "Irritability",
    "Difficulty concentrating",
    "Panic attacks",
    "Difficulty sleeping",
    "Anticipating the worst",
  ],
  Depression: [
    "Feelings of hopelessness or worthlessness",
    "Loss of interest",
    "Loss of energy",
    "Irritability",
    "Negative or suicidal thoughts",
    "Difficulty concentrating",
    "Difficulty sleeping",
    "Avoiding friends, family, and activities.",
  ],
  Bipolar: [
    "Feeling euphoric, excessively happy",
    "More sociable and talkative",
    "Decreased need for sleep",
    "Racing thoughts",
    "Impulsive or risky behavior",
    "Loss of interest",
    "Feeling hopeless, empty, or down most of the time",
    "Insomnia",
    "Feelings of worthlessness or guilt",
    "Difficulty concentrating",
  ],
  "Post Traumatic Stress Disorder": [
    "Flashbacks",
    "Nightmares",
    "Intrusive thoughts",
    "Avoiding places, people, and activities",
    "Feeling hopeless, guilty, ashamed, or worthless",
    "Loss of interest in activities",
    "Hypervigilance",
    "Irritability or angry outbursts",
    "Difficulty concentrating",
    "Trouble falling asleep, staying asleep, or experiencing restless sleep",
  ],
  Schizophrenia: [
    "Hallucinations",
    "Paranoid delusions",
    "Erratic, unpredictable, or excessive movement",
    "Avoiding social interactions and isolating",
    "Struggling to focus or concentrate on tasks",
  ],
  ADHD: [
    "Trouble staying focused on tasks or activities",
    "Forgetfulness",
    "Difficulty organizing tasks and activities",
    "Easily distracted",
    "Fidgeting or squirming",
    "Excessive talking",
    "Impatience",
  ],
};

/* Future Functionality */
export const intake_sections = [
  "benefits",
  "work",
  "finances",
  "ability",
  "disability",
  "extra",
];

export const INTAKE_SECTIONS_DISABLED = true;
