"use client";

import { Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { FaFacebook, FaHandsHelping } from "react-icons/fa";
import {
  FaEnvelope,
  FaHeadset,
  FaHeartCirclePlus,
  FaLinkedin,
  FaLock,
  FaPersonRunning,
  FaXTwitter,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa6";

const icons: {
  [key: string]: IconType;
} = {
  FaHandsHelping: FaHandsHelping,
  FaHeartCirclePlus: FaHeartCirclePlus,
  FaPersonRunning: FaPersonRunning,
  FaHeadset: FaHeadset,
  FaFacebook: FaFacebook,
  FaEnvelope: FaEnvelope,
  FaLock: FaLock,
  FaLinkedin: FaLinkedin,
  FaXTwitter: FaXTwitter,
  FaEye: FaEye,
  FaEyeSlash: FaEyeSlash,
};

const ClientIcon = ({
  icon,
  color,
  size,
}: {
  icon: string;
  color: string;
  size: string;
}) => {
  return <Icon color={color} boxSize={size} as={icons[icon]} />;
};

export default ClientIcon;
