export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = () => {
  if (typeof window !== "undefined" && window.fbq) {
    window.fbq("track", "PageView");
  }
};

export const trackMetaPixelEvent = (name: string) => {
  if (
    process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID &&
    typeof window !== "undefined" &&
    window.fbq
  ) {
    window.fbq("track", name);
  }
};
